<template>
  <div class="mb-3 card">
    <div>
      <v-form lazy-validation ref="form">
        <v-tabs fixed-tabs v-model="tab">
          <v-tab key="baseData">
            {{ $t('erp.lang_baseData') }}
          </v-tab>
          <v-tab key="pricesize" v-if="this.$store.getters['permissions/checkModule'](24)">{{
              $t('erp.langPriceSizeMatrix')
            }}
          </v-tab>
          <v-tab key="picture">{{ $t('erp.lang_productImage') }}</v-tab>
          <v-tab key="wareattributes">{{ $t('erp.lang_warecreate_wareattr') }}</v-tab>
          <v-tab key="fibu">{{ $t('accounting.lang_fibuKonten') }}</v-tab>

          <v-tabs-items touchless v-model="tab">
            <v-tab-item key="baseData">
              <v-container fluid>
                <v-layout wrap>
                  <v-flex md5 xs12>
                    <v-row>
                      <v-col cols="12">

                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_ware_create_id')"
                                      :rules="[v => !!v  , this.duplicatedEAN || $t('erp.lang_barcodeTaken')]"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      @change="checkEAN"
                                      required
                                      :loading="this.loadingCheck"
                                      v-model="itemEAN"
                                      @blur="checkEAN"
                        >
                          <template slot="append">
                            <div style="margin-top:-5px;">
                              <font-awesome-icon :icon="['fal', 'barcode']" @click="GetWareId"
                                                 class="linkCursor" size="2x"/>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12">

                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.internal_wareNo')"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      @change="checkInternID"
                                      @blur="checkInternID"
                                      :loading="loadingCheckInternID"
                                      :rules="[ this.duplicatedInternID || $t('erp.lang_duplicatedInternalId')]"
                                      v-model="itemInternID"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_posItemName')"
                                      :rules="[v => !!v]"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      required
                                      v-model="itemName"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">


                        <v-menu
                            :close-on-content-click="menu_show_group = false"
                            :nudge-bottom="0"
                            :nudge-top="0"
                            bottom
                            class="bt-0 mt-0"
                            id="menu"
                            offset-y
                            ref="groupsMenu"
                            v-model="menu_show_group"
                        >
                          <template v-slot:activator="{on}">
                            <v-text-field
                                :label="$t('generic.lang_waregroup')"
                                :readonly="false"
                                @click:append="show_newGroupAdd"
                                append-icon="add"
                                item-text="name"
                                item-value="id" outlined v-model="itemGroupName"
                                v-on="on">

                              <template v-slot:prepend-inner>
                                <v-icon>arrow_drop_down</v-icon>
                              </template>

                              <template v-slot:append>
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-width="200"
                                    id="menu_create"
                                    max-width="304"
                                    offset-y
                                    v-model="menu_add_group"
                                    z-index="0"
                                >
                                  <template v-slot:activator="{ add }">
                                    <v-btn @click="show_newGroupAdd" color="primary"
                                           fab
                                           id="icon-wiregroup-add" small
                                           v-on="add">
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-card outlined>
                                    <v-row align="center"
                                           class="dropdown-menu-header"
                                           justify="center">
                                      <div class="dropdown-menu-header-inner bg-primary"
                                      >
                                        <div class="menu-header-image dd-header-bg-1"></div>
                                        <div class="menu-header-content text-center pa-3 ma-0"
                                        >
                                          <div class="text-center"><h5
                                              class="menu-header-title ">
                                            {{ $t("erp.lang_waregroupsCreate") }}</h5>
                                            <span class="mx-auto text-center">
                                              {{ $t('erp.lang_quickAddItemgroupBodyText') }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </v-row>
                                    <div class="scroll-area-xl pa-2">
                                      <v-form class="ma-0 pa-0"
                                              lazy-validation
                                              onsubmit="return false"
                                              ref="wiregroupForm">


                                        <v-text-field
                                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :label="$t('erp.lang_itemgroup_name')"
                                            :rules="[v => !!v]"
                                            @focus="showTouchKeyboard"
                                            autocomplete="off"
                                            dense
                                            outlined
                                            required
                                            v-model="GroupName"
                                        ></v-text-field>

                                        <v-select
                                            :items="itemGroupOrderbonIDSelector"
                                            :label="$t('settings.langOrderbonPrinter')"
                                            :rules="[v => !!v]"
                                            dense
                                            item-text="name"
                                            item-value="id"
                                            outlined
                                            v-if="$store.getters['permissions/checkModule'](2)"
                                            v-model="itemGroupStdOrderPrinterID"
                                        ></v-select>


                                        <div>
                                          <strong>{{ $t('erp.lang_colourofWaregroup') }}:</strong>
                                          <swatches
                                              inline
                                              v-model="itemGroupColorPicker"
                                              background-color="transparent"
                                          ></swatches>
                                        </div>

                                        <v-divider class="pa-0 ma-0"/>
                                        <div class="text-center">
                                          <button @click="menu_add_group = false"
                                                  class="btn btn-danger ma-2">
                                            {{ $t('generic.lang_cancel') }}
                                          </button>

                                          <button :disabled="loadingCreatWireGroup"
                                                  @click="createWireGroup"
                                                  class="btn btn-primary ma-2">
                                            {{ $t('generic.lang_create') }}
                                          </button>
                                        </div>


                                      </v-form>
                                    </div>
                                  </v-card>
                                </v-menu>
                              </template>


                            </v-text-field>
                          </template>
                          <v-card class="bt-0 mt-0" outlined>
                            <div style="max-height: 300px !important">

                              <v-list>
                                <v-list-item-group v-model="group">
                                  <v-list-item
                                      :class="(itemGroupName!== '' && item.name.toLowerCase().startsWith(itemGroupName.toLowerCase()))? 'blue lighten-4' : ''"
                                      :key="item.id"
                                      @click="$refs.groupsMenu.save(false)"
                                      v-for="(item) in itemGroupNameSelect"
                                      v-show="item.name.toLowerCase().includes(itemGroupName.toLowerCase()) || itemGroupName === null">
                                    <v-list-item-content
                                    >
                                      <v-list-item-title>{{ item.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>


                            </div>
                          </v-card>
                        </v-menu>


                      </v-col>

                      <v-col cols="12">
                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_itemNameTranslation')"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      ref="translationCanvas"
                                      v-model="itemNameTranslation"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_itemNameOnOrderbon')"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      v-model="itemOrderingName"
                                      v-if="this.$store.getters['permissions/checkModule'](2)"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-switch :label="$t('erp.lang_dishOfTheDayControl')"
                                  v-if="this.$store.getters['permissions/checkModule'](2)" v-model="isDailyGoods"
                                  hide-details :true-value="1" :false-value="0">

                        </v-switch>
                        <div class="mt-4" v-if="this.$store.getters['permissions/checkModule'](61)">
                          <strong> {{$t('settings.lang_navScaleSettings')}}:</strong>
                          <v-switch :label="$t('erp.lang_enableWeighting')"
                                    v-model="enableWeighting"
                                    hide-details :true-value="1" :false-value="0">
                          </v-switch>
                          <v-switch
                              v-if="enableWeighting"
                              :label="$t('erp.lang_enableTara')"
                              v-model="enableTara"
                              :true-value="1" :false-value="0">

                          </v-switch>
                          <v-text-field v-if="enableTara&&enableWeighting"
                                        :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                        :label="$t('erp.lang_taraInKg')"
                                        @focus="showTouchKeyboard"
                                        autocomplete="off" suffix="Kg"
                                        type="number"
                                        outlined
                                        v-model="tara" :rules="[v => !!v]"
                          >
                          </v-text-field>
                        </div>
                      </v-col>

                    </v-row>
                  </v-flex>
                  <v-flex md1 xs0>
                  </v-flex>
                  <v-flex md6 xs12>
                    <v-row>
                      <v-col cols="12">

                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_netPurchasePricePerUnit')"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      v-model="itemPurchasePrice"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_ware_create_sprice')+' '+$t('erp.lang_gross')"
                                      :rules="[v => !!v]"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      v-model="itemSalePrice"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12">

                        <v-select
                            :items="filteredUnits" item-text="unit_name" item-value="id"
                            :label="$t('erp.lang_purchaseUnit')"
                            outlined
                            v-model="unitofPurchaseID"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                            :items="filteredUnits" item-text="unit_name" item-value="id"
                            :label="$t('erp.lang_salesunit')"
                            outlined
                            v-model="unitID"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_saleUnitValue')"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      required
                                      v-model="unitValue"
                                      min="1"
                                      type="number"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="this.$store.getters['permissions/checkModule'](2)">
                        <v-row dense>
                          <v-col cols="8" align-self="center">
                            <font-awesome-icon v-if="this.$store.getters['permissions/checkModule'](2)"
                                               style="margin-right:10px;float:left;" :icon="['fal', 'person-carry']"
                                               size="3x"/>
                            <v-switch
                                v-if="this.$store.getters['permissions/checkModule'](2)"
                                v-model="itemDifferentTaxTakeAway" hide-details
                                :label="$t('settings.lang_warecreate_taxsettingouterhouse')"
                            >
                            </v-switch>
                          </v-col>
                          <v-col cols="4" align-self="center">
                            <v-checkbox :label="$t('erp.lang_itemIsMenu')" v-model="isMenu" hide-details
                                        v-if="this.$store.getters['permissions/checkModule'](28)"/>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-if="!isMenu">
                        <v-select :items="itemTaxSelect"
                                  :label="$t('erp.lang_tax_value')"
                                  :rules="[v => !!v]"
                                  item-text="name"
                                  item-value="id"
                                  outlined
                                  v-model="itemTax"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" v-else>
                        <v-expansion-panels class="border" style="border-color: grey !important;">
                          <v-expansion-panel
                          >
                            <v-expansion-panel-header>
                              {{ $t('erp.lang_menuTaxRules') }}
                            </v-expansion-panel-header>
                            <v-divider class="ma-0"/>
                            <v-expansion-panel-content>
                              <v-card>
                                <v-card-text class="px-2">
                                  <v-row v-for="(taxRule,key) in taxRules" :key="key">
                                    <template>
                                      <v-col cols="6">
                                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                      :label="$t('generic.lang_ofWhichInPercent')"
                                                      @focus="showTouchKeyboard"
                                                      :rules="[v=>(Number(v)>0 )|| $t('generic.lang_requiredField')]"
                                                      :error="validTax" :error-messages="validTaxMsg"
                                                      autocomplete="off"
                                                      outlined
                                                      required
                                                      v-model="taxRule.rate"
                                                      @keyup="taxRulesInput(taxRule)" @blur="reset(taxRule)"
                                                      min="1"
                                                      type="number"
                                        />
                                      </v-col>
                                      <v-col cols="6">

                                        <v-text-field :label="$t('erp.lang_tax_value')"
                                                      @focus="showTouchKeyboard"
                                                      autocomplete="off"
                                                      readonly
                                                      outlined
                                                      required
                                                      :value="taxRule.name"
                                        />
                                      </v-col>
                                    </template>
                                  </v-row>
                                </v-card-text>

                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col cols="12">
                        <v-select :items="itemDepositSelect"
                                  :label="$t('erp.lang_warecreate_pfand')"
                                  item-text="name"
                                  item-value="id"
                                  outlined
                                  v-if="this.$store.getters['permissions/checkModule'](7)"
                                  v-model="itemDeposit"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select :items="itemProducerSelect"
                                  :label="$t('erp.lang_ware_producer_name')"
                                  item-text="name"
                                  item-value="id"
                                  outlined
                                  v-model="itemProducer"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12 xs12>
                    <v-textarea :label="$t('erp.lang_ware_create_desc')"
                                name="itemDescription"
                                outlined
                                v-model="itemDescription"
                                value=""
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex md5 xs12>
                    <v-select :items="itemOrderbonIDSelector"
                              :label="$t('settings.langOrderbonPrinter')"
                              item-text="name"
                              item-value="id"
                              outlined
                              v-if="this.$store.getters['permissions/checkModule'](2)"
                              v-model="itemOrderbonID"
                    ></v-select>

                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_zubereitungsZeitMin')"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  outlined
                                  type="number"
                                  v-if="this.$store.getters['permissions/checkModule'](2)"
                                  v-model="itemPrepartionTime"
                    >
                    </v-text-field>
                    <fontawesome-picker :fieldLabel="$t('erp.lang_chooseItemIcon')"
                                        v-model="selectedIcon"></fontawesome-picker>
                  </v-flex>
                  <v-flex md1 xs0>
                  </v-flex>
                  <v-flex md6 xs12>
                    <strong>{{ $t('erp.lang_warecreate_PLUColor') }}:</strong>
                    <swatches
                        inline v-model="itemColorPicker"
                        background-color="transparent"
                    ></swatches>

                    <strong>{{ $t('erp.mealType') }}</strong><br>

                    <v-radio-group row v-model="itemTypeChooser">
                      <v-radio v-if="this.$store.getters['permissions/checkModule'](2)"
                               :key="0"
                               :value="1"
                      >
                        <template slot="label">
                          <div>
                            <font-awesome-icon :icon="['fal', 'cocktail']" size="3x"/>
                            <p>{{ $t('generic.lang_beverage') }}</p></div>
                        </template>
                      </v-radio>

                      <v-radio v-if="this.$store.getters['permissions/checkModule'](2)"
                               :key="1"
                               :value="2"
                      >
                        <template slot="label">
                          <div>
                            <font-awesome-icon :icon="['fal', 'meat']" size="3x"/>
                            <p>{{ $t('generic.lang_food') }}</p></div>
                        </template>
                      </v-radio>

                      <v-radio
                          :key="2"
                          :label="`Radio`"
                          :value="3"
                      >
                        <template slot="label">
                          <div>
                            <font-awesome-icon :icon="['fal', 'gifts']" size="3x"/>
                            <p>{{ $t('generic.lang_item') }}</p></div>
                        </template>
                      </v-radio>
                      <v-radio
                          :key="3"
                          :label="`Radio`"
                          :value="4"
                      >
                        <template slot="label">
                          <div>
                            <font-awesome-icon :icon="['fal', 'concierge-bell']" size="3x"/>
                            <p>{{ $t('generic.lang_service') }}</p></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex md4 xs12>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_warecreate_priceinputauto')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="openPriceInput"
                    ></v-checkbox>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_wareWeightQTYAutoOpen')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="openWeightQTYInput"
                    ></v-checkbox>

                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_ware_saisonalware')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="saisonalItem"
                    ></v-checkbox>

                    <v-checkbox
                        :hide-details=true
                        :label="$t('settings.lang_settings_activ')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="itemIsActive"
                    ></v-checkbox>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.chargeNoRequired')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="chargeNoRequired"
                    ></v-checkbox>

                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.mhdRequired')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="mhdRequired"
                    ></v-checkbox>

                    <v-checkbox
                        :hide-details=true
                        v-if="this.$store.getters['permissions/checkModule'](53)"
                        :label="$t('erp.lang_askForSerialNumbers')"
                        autocomplete="off"
                        :value="1"
                        style="margin-top:0px !important;"
                        v-model="serialNumberActive"
                    ></v-checkbox>
                  </v-flex>


                  <v-flex md4 xs12>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_warecreate_itemnameask')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="openItemNameChange"
                    ></v-checkbox>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_isService')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="itemIsService"
                    ></v-checkbox>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_includeItemProjectReport')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="includeItemIntoProjectReport"
                    ></v-checkbox>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_pagerNumberREQ')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        :false-value = 0
                        :true-value = 1
                        v-model="pagerNoReq"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md4 xs12>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_auszahlArtikel')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="payOutItem"
                    ></v-checkbox>
                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_bookingNumberREQ')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="bookingNoReq"
                    ></v-checkbox>

                    <v-text-field 
                      v-if="bookingNoReq"
                      v-model="bookingNoRegExp"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      :label="$t('erp.lang_optionalBookingNoValidationRegex')"
                      autocomplete="off"
                      outlined
                      class="mt-4"
                    ></v-text-field>

                    <v-checkbox
                        :hide-details=true
                        :label="$t('erp.lang_includeIntoStats')"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="includeInStatistics"
                    ></v-checkbox>

                    <v-radio-group v-model="ageVerification">
                      <v-radio
                          key="1"
                          :label="$t('erp.lang_ageVerification') +' '+ $t('settings.lang_settings_deactiviert')"
                          value="0"
                      ></v-radio>
                      <v-radio
                          key="2"
                          :label="$t('erp.lang_ageVerification') + ' 16 '+$t('generic.lang_yearsOfAge')"
                          value="16"
                      ></v-radio>
                      <v-radio
                          key="3"
                          :label="$t('erp.lang_ageVerification') +' 18 '+$t('generic.lang_yearsOfAge')"
                          value="18"
                      ></v-radio>

                    </v-radio-group>

                  </v-flex>
                  <v-flex v-if="this.$store.getters['permissions/checkModule'](27)" md4 xs12>
                    <p>{{$t('generic.lang_barcodeWeightPriceMode')}}</p>
                    <v-btn-toggle class="mb-1" active-class="success" v-model="barcodeWeightPriceMode">
                      <v-btn :value="0" class="ma-0">
                        {{ $t('generic.lang_standard') }}
                      </v-btn>

                      <v-btn :value="1" class="ma-0">
                        {{ $t('generic.lang_cashier_price') }}
                      </v-btn>

                      <v-btn :value="2" class="ma-0">
                        {{ $t('generic.lang_weightBarcode') }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>


            <v-tab-item key="pricesize" v-if="this.$store.getters['permissions/checkModule'](24)">
              <v-container fluid>
                <v-row align="center" justify="center">

                  <v-col class="text-right" cols="12">
                    <v-spacer/>

                    <v-dialog max-width="600px" persistent v-model="pricesize">
                      <template v-slot:activator="{ on }">
                        <button @click="pricesize = true"
                                class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 mx-auto"
                                type="button"
                                v-on="on">
                          <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
                          {{ $t('generic.lang_create') }}
                        </button>
                      </template>

                      <v-card>
                        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
                          {{ $t('erp.addSizeVariation') }}
                        </v-card-title>

                        <v-card-text>

                          <v-row align-content="center" justify="center">
                            <v-col cols="12" md6 ml-2 mr-2 sm="6">
                              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :label="$t('erp.lang_SizeVariation')"
                                            @focus="showTouchKeyboard"
                                            autocomplete="off"
                                            outlined
                                            required
                                            v-model="mealVarAdd"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :label="$t('generic.lang_sorting')"
                                            @focus="showTouchKeyboard"
                                            autocomplete="off"
                                            outlined
                                            required
                                            step="0.01"
                                            type="number"
                                            v-model="mealVarPosAdd"
                              ></v-text-field>
                            </v-col>

                          </v-row>

                        </v-card-text>

                        <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="text-right card-footer">
                          <v-spacer/>
                          <v-btn @click="pricesize = false" color="error">{{
                              $t('generic.lang_prev')
                            }}
                          </v-btn>
                          <v-btn :disabled="(mealVarAdd.length < 1) || this.createPriceSizeLoader"
                                 :loading="this.createPriceSizeLoader"
                                 @click="createPriceSize"
                                 color="success">{{
                              $t('generic.lang_add')
                            }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12">
                    <b-table :fields="mealSizesFields" :items="mealSizes" hover ref="priceSizeTable"
                             responsive
                             striped :table-variant="this.$vuetify.theme.dark? 'dark' : ''">
                      <template v-slot:cell(totalprice)="data">
                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      outlined
                                      v-model="data.item.totalprice"
                        ></v-text-field>
                      </template>
                    </b-table>
                  </v-col>
                </v-row>

              </v-container>
            </v-tab-item>
            <v-tab-item key="picture">
              <v-container fluid>
                <v-layout>
                  <v-flex md12 ml-2 mr-2>
                    <image-cropper ref="imageCropper" v-model="productImage"/>
                  </v-flex>
                </v-layout>

              </v-container>
            </v-tab-item>
            <v-tab-item key="wareattributes">
              <v-container fluid>
                <v-layout row wrap>
                  <v-flex md4 ml-2 mr-2 xs12>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_warecreate_biozertno')"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  outlined
                                  v-model="biozertnr"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md4 ml-2 mr-2 xs8>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_warecreate_biotext')"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  outlined
                                  v-model="BioText"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3 ml-2 mr-2>
                    <v-checkbox
                        :label="$t('erp.lang_warecreate_bioware')"
                        autocomplete="off"
                        v-model="bio_yes_no"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <label style="font-size:120%;">{{ $t("erp.lang_Zusatzstoffe") }}</label>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex :key="'z-' + index" md4 v-for="(additive, index) in additives" xs12>
                    <v-checkbox
                        :hide-details=true
                        :label="additive.label"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="additives[index].selected"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <br>
                <v-layout>
                  <v-flex><br>
                    <label style="font-size:120%;">{{ $t("erp.lang_Allergene") }}</label>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex :key="'a-' + index" md4 v-for="(allergen, index) in allergene" xs12>
                    <v-checkbox
                        :hide-details=true
                        :label="allergen.label"
                        autocomplete="off"
                        style="margin-top:0px !important;"
                        v-model="allergene[index].selected"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>

            <v-tab-item key="fibu">
              <v-container fluid>
                <v-layout>
                  <v-flex md12 ml-2 mr-2>
                    <p>{{ $t('accounting.lang_hint_FibuKontenDescr') }}</p>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex md5 ml-2 mr-2 xs12>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  :label="$t('accounting.fibuAccNumber')"
                                  outlined
                                  v-model="fibuAccNumber"
                    ></v-text-field>
                  </v-flex>

                  <v-flex md5 ml-2 mr-2 xs12>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  :label="$t('accounting.lang_fibuBooKingKeyTax')"
                                  outlined
                                  v-model="fibuBooKingKeyTax"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex md5 ml-2 mr-2 xs12>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  :label="$t('accounting.costcentre') + ' 1'"
                                  outlined
                                  v-model="costCentre1"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md5 ml-2 mr-2 xs12>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  :label="$t('accounting.costcentre') + ' 2'"
                                  outlined
                                  v-model="costCentre2"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>


          </v-tabs-items>
        </v-tabs>
      </v-form>
      <v-container fluid :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
        <v-layout>
          <v-flex class="text-right">
            <v-btn :disabled="this.creating" @click="goBacktoItem" color="error" text>{{
                $t('generic.lang_prev')
              }}
            </v-btn>
            <v-btn :disabled="this.creating" :loading="this.creating" @click="addData" color="success">{{
                $t('generic.lang_add')
              }}
            </v-btn>
            <v-btn :disabled="this.creating" :loading="this.creating" @click="createAndGoToItem" color="primary">{{
                $t('erp.lang_createAndGoToItem')
              }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
    <div style="width:300px;height:300px;margin: auto;display:none;">
      <canvas id="canvas"></canvas>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '../../../config'
import {Events} from "../../../plugins/events";
import Swatches from 'vue-swatches';
import ImageCropper from "../../common/imagecropper";
import "vue-swatches/dist/vue-swatches.css"
import {library} from '@fortawesome/fontawesome-svg-core';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'


import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faConciergeBell,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faHamburger,
  faHotdog,
  faMeat,
  faPersonCarry,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco
} from '@fortawesome/pro-light-svg-icons'


import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import mixin from "../../../mixins/KeyboardMixIns";
import FontawesomePicker from "../../common/iconPicker"
import Template from "../../settings/customerdisplay/Template";
import Layout from "../../../views/settings/cashier/Layout";

library.add(
    faHotdog,
    faBarcode,
    faCocktail,
    faPersonCarry,
    faMeat,
    faGifts,
    faConciergeBell,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad,
    faTaco,
    faSandwich,
    faSoup
);

export default {
  components: {
    Layout,
    Template,
    VuePerfectScrollbar,
    Swatches,
    ImageCropper,
    FontawesomePicker,
    'font-awesome-icon': FontAwesomeIcon,
  },
  mixins: [mixin],
  data() {
    return {
      creating: false,
      duplicatedEAN: false,
      duplicatedInternID: false,
      loadingCheck: false,
      loadingCheckInternID: false,
      loadingCreatWireGroup: false,
      itemGroupStdOrderPrinterID: "",
      itemGroupOrderbonIDSelector: [],
      itemGroupColorPicker: "",
      GroupName: "",
      menu_add_group: null,
      menu_show_group: null,
      ENDPOINTS,
      createPriceSizeLoader: false,
      mealVarAdd: "",
      mealVarPosAdd: "",
      pricesize: false,
      ageVerification: "0",
      serialNumberActive: 0,
      mealSizesFields: [
        {
          key: 'sizevariation',
          label: this.$t("erp.lang_SizeVariation"),
          sortable: true,
          'class': 'text-left'
        },
        {
          key: 'totalprice',
          label: this.$t("erp.lang_newTotalPrice"),
          sortable: false,
          'class': 'text-center'
        },
      ],
      select: '',
      selectedIcon: "",
      itemEAN: null,
      itemInternID: null,
      itemName: "",
      id: null,
      itemPrepartionTime: null,
      itemDeposit: null,
      itemDepositSelect: [],
      itemProducer: null,
      itemProducerSelect: [],
      itemTax: null,
      itemOrderbonIDSelector: [],
      itemOrderbonID: null,
      bookingNoReq: 0,
      bookingNoRegExp: "",
      pagerNoReq: 0,
      payOutItem: 0,
      itemIsService: 0,
      includeItemIntoProjectReport: 0,
      openItemNameChange: 0,
      includeInStatistics: 1,
      itemIsActive: 1,
      chargeNoRequired: 0,
      mhdRequired: 0,
      itemTypeChooser: 3,
      itemDescription: "",
      saisonalItem: 0,
      openWeightQTYInput: 0,
      openPriceInput: 0,
      itemColorPicker: null,
      itemPurchasePrice: null,
      itemSalePrice: null,
      itemNameTranslation: null,
      itemOrderingName: null,
      itemTaxSelect: [],
      itemGroupNameSelect: [],
      itemGroupName: "",
      itemDifferentTaxTakeAway: 0,
      biozertnr: "",
      BioText: "",
      bio_yes_no: 0,
      productImage: null,
      additives: [],
      allergene: [],
      mealSizes: [],
      fibuAccNumber: "",
      fibuBooKingKeyTax: "",
      costCentre1: "",
      costCentre2: "",
      itemAdd: "",
      loading: false,
      tab: 0,
      group: "",
      units: [],
      unitID: null,
      unitofPurchaseID: null,
      unitValue: null,
      isMenu: false,
      taxRules: [],
      validTax: false,
      validTaxMsg: '',
      isDailyGoods: 0,
      enableWeighting: false,
      enableTara: false,
      tara:null,
      barcodeWeightPriceMode:0,
    }
  },

  watch: {
    group(val) {
      if (val === undefined) {
        this.itemGroupName = "";
        return
      }
      this.itemGroupName = this.itemGroupNameSelect[val].name;
    }
  },
  computed: {
    allergeneIDs() {
      let ids = [];

      this.allergene.forEach((allergen) => {
        if (allergen.selected) {
          ids.push(allergen.key);
        }
      });

      return ids;
    },
    additivesIDs() {
      let ids = [];

      this.additives.forEach((additive) => {
        if (additive.selected) {
          ids.push(additive.key);
        }
      });

      return ids;
    },
    filteredUnits() {
      if (!this.units || !this.units.length) {
        return [];
      }
      let filtered = [];
      filtered = this.units.map(unit => {
        if (!unit && unit.unit_name.length === 0)
          return;

        if (unit.unit_name.includes("lang_"))
          unit.unit_name = this.$t('erp.' + unit.unit_name);

        return unit;
      })
      return filtered;
    }
  },
  methods: {
    taxRulesInput(item) {
      let rest = 100;
      if (Number(item.rate) < 0) {
        item.rate = 0;
        return;
      }

      this.taxRules.forEach(rule => {
        if (item.id !== rule.id) {
          rest -= Number(rule.rate);
        }
      })

      if (Number(item.rate) > rest) {
        item.rate = rest;
      }

    },
    reset(item) {
      this.taxTotal = 0;
      let rest = 100;

      this.validTax = true;
      this.validTaxMsg = this.$t('generic.lang_adjustFieldsTo100');

      this.taxRules.forEach(rule => {
        if (item.id !== rule.id) {
          rest -= Number(rule.rate);
        }
        this.taxTotal += Number(rule.rate)
      })

      if (!item.rate || item.rate === '' || !Number(item.rate)) {
        item.rate = 0;
      }

      if (this.taxTotal === 100) {
        this.validTax = false;
        this.validTaxMsg = '';
      }

    },
    checkEAN() {
      this.loadingCheck = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEEAN, {
        //itemId: this.mainItemId,
        barcode: this.itemEAN
      }).then((res) => {
        this.duplicatedEAN = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheck = false;
      })
    },
    checkInternID() {
      this.loadingCheckInternID = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEINTERNALID, {
        //itemId: this.mainItemId,
        internalId: this.itemInternID
      }).then((res) => {
        this.duplicatedInternID = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheckInternID = false;
      })
    },
    getUnits() {
      this.axios.post(ENDPOINTS.ERP.UNITS.GET).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          this.units = res.data.units;
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + this.$t('erp.lang_whileGettingUnits'),
          color: "error"
        });
      });
    },
    createWireGroup() {
      if (!this.$refs.wiregroupForm.validate()) return;
      let form = {};
      form.wareGroupName = this.GroupName;
      form.orderBonPrinterID = this.itemGroupStdOrderPrinterID;
      form.createWaregroupColor = this.itemGroupColorPicker;
      this.loadingCreatWireGroup = true;

      this.axios.post(ENDPOINTS.ERP.ITEMGROUP.CREATE, form).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemgroupCreated'),
            color: "success"
          });

          if (this.itemGroupNameSelect === null)
            this.itemGroupNameSelect = []
          this.itemGroupNameSelect.push({
            id: parseInt(res.data.id),
            name: this.GroupName
          });

          //this.getData("wireGroup");

          if (this.itemGroupNameSelect && this.itemGroupNameSelect.length > 0) {
            //let tmp = this.itemGroupNameSelect[this.itemGroupNameSelect.length - 1];
            this.group = this.itemGroupNameSelect.length - 1;
            this.itemGroupName = this.GroupName;
          }
          this.menu_add_group = false;
          this.$refs.wiregroupForm.reset();


        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCreatWireGroup = false;
      });
    },
    show_newGroupAdd() {
      this.menu_add_group = true;
    },
    createPriceSize: function () {
      let self = this;
      self.createPriceSizeLoader = true;
      this.axios.post(ENDPOINTS.ERP.SIZEVARIATION.CREATE, {
        addMealVarName: self.mealVarAdd,
        addMealVarSort: self.mealVarPosAdd
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_sizeVariation_added'),
            color: "success"
          });
          self.mealVarAdd = "";
          self.mealVarPosAdd = 0;
          self.pricesize = false;
          self.getData();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        self.createPriceSizeLoader = false;
      });
    },
    goBacktoItem: function () {
      this.$router.push('/erp/baseData/items');
    },
    goToEditItem(itemID) {
      this.$router.push({name: 'erp.baseData.items.edit', params: {id: parseInt(itemID)}})
    },
    convert(text) {
      if (document.getElementById('canvas') != null) {
        let canvas = document.getElementById('canvas');

        let canvasEPOSString = 0;
        if (canvas.getContext) {
          let ctx = canvas.getContext('2d');
          canvas.width = 200;
          canvas.height = 100;

          ctx.font = "1.5em orbitron";
          ctx.textBaseline = "top";
          ctx.maxWidth = canvas.width;

          ctx.fillStyle = "transparent";
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = "black";
          ctx.fillText(text === null ? '' : text, 0, 0);

          let builder = new this.$epson.ePOSBuilder();
          try {
            builder.brightness = 1.0;
            builder.halftone = builder["HALFTONE_ERROR_DIFFUSION"];
            builder.addImage(ctx, 0, 0, canvas.width, canvas.height);
            canvasEPOSString = builder.message.replace(/<.+?>/g, '');
            return canvasEPOSString;
          } catch (e) {
            //console.log(e.message);
          }
        }
      }
      return null;
    },
    addData: function () {
      if (!this.$refs.form.validate()) {
        this.tab = 0;
        this.$vuetify.goTo(0);
        return
      }

      this.creating = true;

      let tmpGroup = "";

      if (this.group) {
        tmpGroup = this.itemGroupNameSelect[this.group].id
      }

      if (this.$refs.hasOwnProperty("imageCropper"))
        this.$refs.imageCropper.cropImage();

      let menuTaxRules = {};
      if (this.taxRules && this.taxRules.length > 0) {
        this.taxRules.forEach(rule => {
          menuTaxRules[rule.id] = rule.rate;
        })
      }


      this.axios.post(ENDPOINTS.ERP.ITEM.CREATE, {
        ware_ID: this.itemEAN,
        ware_internalNo: this.itemInternID,
        ware_name: this.itemName,
        ware_buyprice: this.itemPurchasePrice,
        wareSellingPrice: this.itemSalePrice,
        createItemWareGroupId: tmpGroup,
        createItemTaxId: this.itemTax,
        createItemOrderbonPrinter: this.itemOrderbonID,
        createItemPfandGroupID: this.itemDeposit,
        itemProducerId: this.itemProducer,
        gastroFoodType: this.itemTypeChooser,
        createItemDescr: this.itemDescription,
        createItemZubereitungsZeitInMin: this.itemPrepartionTime,
        createItemPLUColor: this.itemColorPicker,
        radio_ausserhaus_tax: this.itemDifferentTaxTakeAway,
        autoPreiseingabe: this.openPriceInput,
        autoWeightQTYInput: this.openWeightQTYInput,
        mealSizes: this.mealSizes,
        itemActive: this.itemIsActive,
        isService: this.itemIsService,
        includeItemIntoProjectReport: this.includeItemIntoProjectReport,
        chargeNoRequired: this.chargeNoRequired,
        mhdRequired: this.mhdRequired,
        serialNumberActive: this.serialNumberActive,
        allowSetName: this.openItemNameChange,
        auszahlartikel: this.payOutItem,
        bookingNumberReq: this.bookingNoReq,
        bookingNoRegExp: this.bookingNoRegExp,
        pagerNumberReq: this.pagerNoReq,
        includeInStatistics: this.includeInStatistics,
        allergene: this.allergeneIDs,
        zusaetze: this.additivesIDs,
        productImage: this.productImage,
        biozertnr: this.biozertnr,
        BioText: this.BioText,
        bio_yes_no: this.bio_yes_no,
        fibuKontonummer: this.fibuAccNumber,
        buchungsschluessel: this.fibuBooKingKeyTax,
        kost1: this.costCentre1,
        kost2: this.costCentre2,
        itemNameTranslation: this.itemNameTranslation,
        itemOrderingNameTranslation: this.itemOrderingName,
        translateNameCanvas: this.convert(this.itemNameTranslation),
        translateOrderingNameCanvas: this.convert(this.itemOrderingName),
        ageVerification: this.ageVerification,
        unitID: parseInt(Number(this.unitID)),
        unitofPurchaseID: parseInt(Number(this.unitofPurchaseID)),
        unitValue: parseFloat(this.unitValue),
        isMenuItem: this.isMenu ? 1 : 0,
        enableTara: this.enableTara ? 1 : 0,
        enableWeighting: this.enableWeighting ? 1 : 0,
        tara: this.tara,
        menuTaxRules: menuTaxRules,
        isDailyGoods: parseInt(this.isDailyGoods),
        barcodeWeightPriceMode:this.barcodeWeightPriceMode,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemSuccessfulAdded'),
            color: "success"
          });

          this.goBacktoItem();
        } else if (res.data.status === 'CHECK ERROR' || res.data.status === 'duplicateItem') {

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_eanCheckFailed'),
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => this.creating = false);
    },
    createAndGoToItem: function () {
      if (!this.$refs.form.validate()) {
        this.tab = 0;
        this.$vuetify.goTo(0);
        return
      }

      this.creating = true;

      let tmpGroup = "";

      if (this.group) {
        tmpGroup = this.itemGroupNameSelect[this.group].id
      }

      if (this.$refs.hasOwnProperty("imageCropper"))
        this.$refs.imageCropper.cropImage();

      let menuTaxRules = {};
      if (this.taxRules && this.taxRules.length > 0) {
        this.taxRules.forEach(rule => {
          menuTaxRules[rule.id] = rule.rate;
        })
      }


      this.axios.post(ENDPOINTS.ERP.ITEM.CREATE, {
        ware_ID: this.itemEAN,
        ware_internalNo: this.itemInternID,
        ware_name: this.itemName,
        ware_buyprice: this.itemPurchasePrice,
        wareSellingPrice: this.itemSalePrice,
        createItemWareGroupId: tmpGroup,
        createItemTaxId: this.itemTax,
        createItemOrderbonPrinter: this.itemOrderbonID,
        createItemPfandGroupID: this.itemDeposit,
        itemProducerId: this.itemProducer,
        gastroFoodType: this.itemTypeChooser,
        createItemDescr: this.itemDescription,
        createItemZubereitungsZeitInMin: this.itemPrepartionTime,
        createItemPLUColor: this.itemColorPicker,
        radio_ausserhaus_tax: this.itemDifferentTaxTakeAway,
        autoPreiseingabe: this.openPriceInput,
        autoWeightQTYInput: this.openWeightQTYInput,
        mealSizes: this.mealSizes,
        itemActive: this.itemIsActive,
        isService: this.itemIsService,
        includeItemIntoProjectReport: this.includeItemIntoProjectReport,
        chargeNoRequired: this.chargeNoRequired,
        mhdRequired: this.mhdRequired,
        serialNumberActive: this.serialNumberActive,
        allowSetName: this.openItemNameChange,
        auszahlartikel: this.payOutItem,
        bookingNumberReq: this.bookingNoReq,
        bookingNoRegExp: this.bookingNoRegExp,
        pagerNumberReq: this.pagerNoReq,
        includeInStatistics: this.includeInStatistics,
        allergene: this.allergeneIDs,
        zusaetze: this.additivesIDs,
        productImage: this.productImage,
        biozertnr: this.biozertnr,
        BioText: this.BioText,
        bio_yes_no: this.bio_yes_no,
        fibuKontonummer: this.fibuAccNumber,
        buchungsschluessel: this.fibuBooKingKeyTax,
        kost1: this.costCentre1,
        kost2: this.costCentre2,
        itemNameTranslation: this.itemNameTranslation,
        itemOrderingNameTranslation: this.itemOrderingName,
        translateNameCanvas: this.convert(this.itemNameTranslation),
        translateOrderingNameCanvas: this.convert(this.itemOrderingName),
        ageVerification: this.ageVerification,
        unitID: parseInt(Number(this.unitID)),
        unitofPurchaseID: parseInt(Number(this.unitofPurchaseID)),
        unitValue: parseFloat(this.unitValue),
        isMenuItem: this.isMenu ? 1 : 0,
        enableTara: this.enableTara ? 1 : 0,
        enableWeighting: this.enableWeighting ? 1 : 0,
        tara: this.tara,
        menuTaxRules: menuTaxRules,
        isDailyGoods: parseInt(this.isDailyGoods),
        barcodeWeightPriceMode: this.barcodeWeightPriceMode,

      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemSuccessfulAdded'),
            color: "success"
          });

          this.goToEditItem(res.data.itemID);
        } else if (res.data.status === 'CHECK ERROR' || res.data.status === 'duplicateItem') {

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_eanCheckFailed'),
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => this.creating = false);
    },
    getData: function (toLoad = "") {
      this.axios.post(ENDPOINTS.ERP.ITEM.GET, {}).then((res) => {
        if (res.status === 200) {
          this.mealSizes = [];
          this.itemGroupNameSelect = [];
          if (toLoad === "wireGroup") {

            res.data.waregroups.forEach((row) => {
              this.itemGroupNameSelect.push({name: row[1], id: parseInt(row[0])});
            });

          } else {

            res.data.waregroups.forEach((row) => {
              this.itemGroupNameSelect.push({name: row[1], id: parseInt(row[0])});
            });

            res.data.tax.forEach((row) => {
              this.itemTaxSelect.push({name: row[2], value: row[1], id: parseInt(row[0])});
              if (row[1] > 0) {
                this.taxRules.push({name: row[2], value: row[1], id: parseInt(row[0]), rate: 0});
              }

              //SET DEFAULT TAX
              if (row[3] > 0) {
                this.itemTax = row[0];
              }

            });

            res.data.pfand.forEach((row) => {
              this.itemDepositSelect.push({name: row[1], id: parseInt(row[0])});
            });

            res.data.producer.forEach((row) => {
              this.itemProducerSelect.push({name: row[1], id: parseInt(row[0])});
            });


            res.data.orderbonPrinters.forEach((row) => {
              this.itemOrderbonIDSelector.push({name: row[1], id: parseInt(row[0])});
              this.itemGroupOrderbonIDSelector.push({name: row[1], id: parseInt(row[0])});
            });


            if (res.data.mealSizes && res.data.mealSizes.length > 0) {
              for (let i = 0; i < res.data.mealSizes.length; i++) {
                this.mealSizes.push({
                  key: res.data.mealSizes[i][0],
                  sizevariation: res.data.mealSizes[i][1],
                  totalprice: 0.00
                })
              }
            }

            if (res.data.allergensArr && res.data.allergensArr.length > 0) {
              let total = res.data.allergensArr.length;
              for (let i = 0; i < total; i++) {
                let allergen = res.data.allergensArr[i]
                let selected = 0
                allergen = {"key": allergen[0], "label": allergen[1], "selected": selected};
                this.allergene.push(allergen)
              }
            }

            if (res.data.additivesArr && res.data.additivesArr.length > 0) {
              let total = res.data.additivesArr.length;
              for (let i = 0; i < total; i++) {
                let additive = res.data.additivesArr[i];
                let selected = 0;
                additive = {"key": additive[0], "label": additive[1], "selected": selected};
                this.additives.push(additive)
              }
            }
          }

          /* PRECHECK REQUIRED EAN AND INTERNAL ID CHECK */
          this.checkEAN();
          this.checkInternID();

        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },

    GetWareId() {
      this.axios.post(ENDPOINTS.ERP.INVENTORYITEM.EAN.CREATE).then((res) => {
        if (res.status === 200) {
          this.itemEAN = res.data && res.data != null && res.data.length > 0 ? res.data[0] : this.itemEAN;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    resetData: function () {
      this.id = null;
      this.text = "";
    },
  },
  mounted() {
    this.getData();
    this.getUnits();
  },
}
</script>

<style scoped>
#icon-wiregroup-add {
  margin: -8px -1px 0 0 !important;
}

.dd-header-bg-1 {
  background-image: url('./../../../assets/images/dropdown-header/abstract1.jpg');
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}

#menu {
  width: 100% !important;
}

#menu_create {
  z-index: 0;
}
</style>
